@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.navbar {
  height: 60px;
  background-color: #fff;
  position: relative;
  z-index: 999999;
}

.navbar .appointment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

.navbar .appointment .book_btn {
  margin-left: 30px;
}

.navbar .appointment .book_btn a {
  background: #26a9e0;
  padding: 12px 26px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.navbar .appointment .book_btn a:hover {
  background: #fff;
  color: #26a9e0;
  border: 1px solid #26a9e0;
}

.menu-icon {
  display: none;
}

.nav-elements {
  text-align: center;
}

.nav-elements ul {
  display: inline-flex;
  position: relative;
  margin: 0 10px;
  list-style-type: none;
}

.nav-elements ul li {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #000;
  font-weight: 700;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
background: rgb(119,201,30);
background: linear-gradient(275deg, rgba(119,201,30,1) 0%, rgba(149,199,95,1) 32%, rgba(219,245,192,1) 74%);
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #88cbe8;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 180px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-top: 22px;
  }
}


